import Swal from "sweetalert2";

const notify = async (options) => {
    const {
        title,
        text,
        icon = "info",
        submitFn,
        confirmButtonText = "OK",
        denyButtonText,
        confirmButtonType
    } = options;

    const iconLabels = {
        success: 'success',
        error: 'danger',
        warning: 'warning',
        info: 'info',
        question: 'primary'
    };
    const buttonType = confirmButtonType || iconLabels[icon];

    try {
        let { value } = await Swal.fire({
            title: title,
            html: text,
            icon: icon,
            confirmButtonText: confirmButtonText,
            denyButtonText: denyButtonText ? denyButtonText : "Cancelar",
            showDenyButton: !!denyButtonText,
            showCancelButton: false,
            reverseButtons: false,
            customClass: {
                confirmButton: `btn btn-${buttonType}`,
                denyButton: `btn btn-outline  btn-outline-danger btn-active-light-danger`
            }
        });

        if (value) {
            try {
                if (submitFn) {
                    await submitFn();
                }
            } catch (e) {
                throw e;
            }
        } else {
            throw new Error("Value is undefined");
        }
    } catch (e) {
        throw e;
    }
};

export default notify;
