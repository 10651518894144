import React, {useState, useRef, forwardRef, useImperativeHandle, useEffect} from 'react';
import {GoogleMap, Marker, LoadScriptNext} from '@react-google-maps/api';

import pinGoogle from "../img/pin-google.png";
import {Env} from "../helpers/Env";
import {Spinner} from "react-bootstrap";
import {IoMdLocate} from "react-icons/io";
import notify from "../libs/swal-builder"

const getAddress = async ({lat, lng, address}) => {
    const geocoder = new window.google.maps.Geocoder();

    try {
        const results = await new Promise((resolve, reject) => {
            let request = {}
            if (lat && lng) {
                request.location = {
                    lat, lng
                }
            }
            if (address) {
                request.address = address
            }
            geocoder.geocode(request, (results, status) => {
                if (status === 'OK') {
                    resolve(results);
                } else {
                    reject(new Error('Geocoder failed: ' + status));
                }
            });
        });

        if (results && results[0]) {
            const {
                address_components,
                formatted_address,
                geometry: {
                    location
                }
            } = results[0];

            const filterGoogleComponents = [
                {
                    type: 'route',
                    accessor: 'street',
                    isLongName: true,
                },
                {
                    type: 'street_number',
                    accessor: 'street_number'
                },
                {
                    type: 'postal_code',
                    accessor: 'postal_code'
                },
                {
                    type: 'sublocality',
                    accessor: 'district'
                },
                {
                    type: 'locality',
                    accessor: 'city'
                },
                {
                    type: 'administrative_area_level_1',
                    accessor: 'state_abbrev'
                },
            ]
            const generateAddress = () => {
                return address_components.reduce((aux, comp) => {
                    filterGoogleComponents.forEach(({ type, accessor, isLongName }) => {
                        if (comp.types.includes(type)) {
                            aux[accessor] = isLongName ? comp.long_name : comp.short_name;
                        }
                    });
                    return aux;
                }, {});
            };

            const address = generateAddress();

            return {
                formatted_address,
                lat: location.lat(),
                lng: location.lng(),
                address,
            };

        } else {
            throw new Error('Nenhum resultado encontrado');
        }
    } catch (error) {
        throw new Error(error?.message || 'Ocorreu um erro');
    }
};

const containerStyle = {
    height: '100%',
    borderRadius: '5px',
    minHeight:400
};

const center = {
    lat: -8.76077,
    lng: -63.90393
};


const mapStyles = [
    {
        featureType: 'poi', // Points of interest
        stylers: [{ visibility: 'simplified' }]
    },
    {
        featureType: 'transit', // Transit stations/lines
        stylers: [{ visibility: 'off' }]
    },
    {
        featureType: 'road', // Hide road landmarks
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }]
    },
    {
        featureType: 'landscape.man_made', // Man-made structures including buildings
        stylers: [{ visibility: 'off' }]
    },
    {
        featureType: 'landscape', // General landscape features
        elementType: 'geometry',
        stylers: [{ visibility: 'simplified' }]
    }
];

const ComponentsMapsPin = forwardRef( (props, ref) => {
    const { onChangeCenter, defaultValue, address } = props;

    const [currentCenter, setCurrentCenter] = useState(center);
    const [userLocation, setUserLocation] = useState(null)
    const [loading, setLoading] = useState(false);
    const [showConfirmButton, setShowConfirmButton] = useState(false);
    const onLoadCalled = useRef(false);
    const [zoom, setZoom] = useState(defaultValue ? 17 : 12)

    const mapRef = useRef(null);
    const [isFetching, setIsFetching] = useState(false);
    const [initialLoading, setInitialLoading] = useState(false);

    const checkGeolocationPermission = () => {
        if (navigator.permissions) {
            return navigator.permissions.query({ name: 'geolocation' })
                .then(permissionStatus => {
                    return (permissionStatus.state)
                });
        }
    };

    const handleUseCurrentLocation = async (type) => {
        try {
            const permission = await checkGeolocationPermission();
            console.log(permission)
            if (permission === "prompt") {
                await notify({
                    title: "Atenção",
                    text: "Em seguida, irá aparecer uma mensagem para você permitir o acesso a sua localização!",
                    icon: "info",
                    confirmButtonText:"Ok",

                })
            } else if (permission === "denied" && type !== "first-loading") {
                throw "Necessário habilitar permissão"
            }

            if (navigator.geolocation) {
                if (type !== "first-loading") setLoading(true);

                navigator.geolocation.getCurrentPosition(
                    (position) => {

                        const location = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                        setCurrentCenter(location);
                        setUserLocation(location);
                        setShowConfirmButton(true);
                        setLoading(false);
                    },
                    (error) => {
                        console.error("Error getting user's location", error);
                    }
                );
            } else {
                console.error('Geolocation is not supported by this browser.');
            }


        } catch (e) {
            await notify({
                title: "Para usar esse recurso é necessário que você habilite o acesso a sua localização!",
                icon: "error",
                confirmButtonText:"Ok"
            })
        }

    };

    const onGetCenter = async () => {
        const newCenter = mapRef.current.getCenter();
        const aux = {
            lat: newCenter.lat(),
            lng: newCenter.lng(),
        }
        try {
            setIsFetching(true)
            if (showConfirmButton) setShowConfirmButton(false);
            const address = await getAddress({lat: aux.lat, lng: aux.lng});

            if (onChangeCenter) {
                onChangeCenter(address)
            }

        } catch (e) {
            // toast.error(e.message || "Ocorreu um erro")
        }
        setIsFetching(false)

    }

    const onMapLoaded = async () => {
        try {

            if (address) {
                const fullAddress = `${address.street_name}, ${address.cep}`

                const res = await getAddress({address: fullAddress})
                if (onChangeCenter) {
                    onChangeCenter(res)
                }
                const { lat, lng } = res
                setCurrentCenter({lat, lng})
                setZoom(16)
            }

        } catch (e) {
            console.log(e)
        }
    }

     useImperativeHandle(ref, () => ({
         onChangeAddress: onMapLoaded,
     }));

    useEffect(() => {
        if (onLoadCalled.current) {
            onMapLoaded()
        }
    }, [address]);

    return (
        <div>
            <LoadScriptNext loadingElement={<div className={"skeleton h-300px"}></div>}
                            onLoad={() => {
                                if (!onLoadCalled.current && !defaultValue) {
                                    onMapLoaded()
                                    onLoadCalled.current = true
                                }
                            }}
                            googleMapsApiKey={Env.google_maps_api_key}
                            libraries={["places"]}
            >
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={currentCenter}
                    zoom={zoom}
                    minZoom={6}
                    maxZoom={15}
                    mapTypeId="roadmap"
                    options={{
                        mapTypeControl: false, // Desabilita opção de tipo de mapa
                        streetView: false,
                        fullscreenControl: false,
                        streetViewControl: false,
                        styles: mapStyles,
                        gestureHandling: "cooperative"

                    }}
                    onDragEnd={() => {
                        if (!showConfirmButton) {
                            setShowConfirmButton(true);
                        }
                    }}
                    onLoad={(map) => {
                        mapRef.current = map
                    }}
                    // onClick={onMapClick}
                >
                    {loading &&
                        <div className={"position-absolute w-100 h-100 inset-0 d-flex flex-center bg-dark bg-opacity-50"}
                             style={{zIndex: 100}}>
                            <div className={"da-flex flex-column gap-2 bg-body p-3 px-5 rounded-2"}>
                                <Spinner size={'sm'}/>
                                <h5 className={"mb-0"}>
                                    Aguarde...
                                </h5>
                            </div>
                        </div>
                    }

                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -100%)',
                        zIndex: 2
                    }}>
                        <img src={pinGoogle}
                               // unoptimized={true}
                               className={"object-fit-contain"}
                               alt={"Pin"}
                        />
                    </div>


                    <div className={"d-flex justify-content-end position-absolute align-self-start"}
                         style={{zIndex: 2, inset: 0, marginTop: 24, marginRight: 10}}>
                        <button type={"button"} disabled={loading}
                                className={"btn-focus da-flex gap-1 fw-bold"}
                                onClick={handleUseCurrentLocation}>
                            <IoMdLocate size={24}/>

                            {/*Usar minha localização atual*/}
                        </button>
                    </div>
                    <>
                        {showConfirmButton &&
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 24,
                                    left: "50%",
                                    transform: 'translate(-50%, 0%)',
                                }}
                            >
                                <button
                                    type={"button"}
                                    disabled={isFetching}
                                    className={"btn btn-sm btn-dark d-flex gap-2 rounded-pill fs-6 fw-bold"}
                                    onClick={onGetCenter}
                                >
                                    <img
                                        className={"object-fit-contain"}
                                        src={pinGoogle}
                                        width={13}
                                        height={24}
                                        alt={"pin"}
                                    />
                                    Confirmar localização
                                </button>
                            </div>
                        }
                    </>


                    {userLocation && (
                        <Marker
                            position={userLocation}
                            icon={{
                                path: "M 0, 0 m -10, 0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0",
                                fillColor: '#4285F4',
                                fillOpacity: 1,
                                strokeWeight: 2,
                                strokeColor: 'white',
                                scale: 1
                            }}
                        />
                    )}
                    {/*{pin && (*/}
                    {/*    <Marker*/}
                    {/*        position={pin}*/}
                    {/*    />*/}
                    {/*)}*/}
                </GoogleMap>
            </LoadScriptNext>
        </div>
    )
})

export default React.memo(ComponentsMapsPin);