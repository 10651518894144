import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {QueryCache, useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {BackButtonComponent} from "../../../../components/Generic/BackButtonComponent";
import {days, years} from "../../../../helpers/AuxiliaryVariables";
import {CalendarRound} from "../CalendarRound";
import Select from "react-select";
import {Button, Spinner} from "react-bootstrap";
import {toast} from "react-hot-toast";
import {api} from "../../../../services/api";
import { FaPlus } from "react-icons/fa";

const ChooseOccupationComponent = ({activeStep, eventKey, onSaveData, isEdit, editData, isRegisterNewOccupation, setSelectedOccupation}, ref) => {
    let query = useQueryClient();

    const allSteps = ['INITIAL', 'CHOOSE_OCCUPATION', 'OCCUPATION_SCHEDULE']
    const [step, setStep] = useState('INITIAL');
    const [selectedSpecialty, setSelectedSpecialty] = useState({});
    const [scheduleList, setScheduleList] = useState([]);
    const [dataProfessional, setDataProfessional] = useState({
        startTime:'08:00',
        endTime:'18:00',
    });
    const ofClock = useRef();
    const toClock = useRef();

    const { data: user } = useQueryClient().getQueryState(['profile']);

    const [occupationsGroups, setOccupationsGroups] = useState([]);

    const {data, isLoading} = useQuery({
        queryKey: ['occupations'],
        queryFn:() => getOccupations(),
        keepPreviousData:true,
        staleTime: 30 * 1000,
    })

    const getOccupations = async () => {
        try {
            return await api.get('search/occupations')
        } catch (e) {
        }
    }

    const {mutate: mutateSubmit, isLoading: isPending} = useMutation({
        mutationKey: ['submit-selected-occupation'],
        mutationFn:() => onSubmit(),
        cacheTime: 30 * 1000,
        onSuccess: async () => {
            await query.refetchQueries({queryKey: ['profile']});
            const t = query.getQueryData(['profile']);

            setSelectedOccupation && setSelectedOccupation(t?.occupations?.find((f) => f.occupation_id === selectedSpecialty.id));
            setStep("INITIAL");
            resetFields();
            onSaveData();
        },
        onError: (error) => {
            console.log(error)
        }
    })
    const getOccupationsGroup = async (item) => {
        setOccupationsGroups(item)
        handleChangeStep('CHOOSE_OCCUPATION')
    }

    const showToast = ({message, icon}) => {
        toast(message || 'Ocorreu um erro', {
            icon: <i className={`bi bi-${icon || 'exclamation-triangle-fill'} fs-2 text-danger text-bg-danger bg-opacity-10 px-2 rounded-2`}></i>,
            ariaProps: {
                className:'text-center'
            },
            id:1
        })
    }

    const onSubmit = async () => {
        let aux = []


        if (scheduleList.length === 0) {
            showToast({message: 'É necessário escolher pelo menos um dia de trabalho'})
            // dispatch(messageFormat('warning', 'É necessário escolher pelo menos um dia de trabalho'));
            return Promise.reject();
        }

        if (!dataProfessional?.startTime || !dataProfessional?.endTime) {
            showToast({message: 'É necessário escolher o horário em que você se encontra disponível'})
            return Promise.reject();
        }

        if (dataProfessional?.experience === undefined) {
            showToast({message: 'É necessário informar o seu tempo de experiência', icon:'hourglass-split'})
            return Promise.reject();
        }

        for (let i = 0; i < scheduleList.length; i++) {
            aux.push({
                start1: dataProfessional?.startTime,
                end1: dataProfessional?.endTime,
                start2: null,
                end2: null,
                dow: scheduleList[i]
            });
        }

        const objToSend = {
            occupation_id: (selectedSpecialty.occupation_id || selectedSpecialty.id),
            experience: parseInt(dataProfessional?.experience),
            work_schedule: aux,
        };

        if (selectedSpecialty?.professional_registration) {
            if (dataProfessional?.extra_fields?.registration) {
                objToSend.extra_fields = {
                    registration: dataProfessional?.extra_fields.registration
                };
            }

        }

        if (isEdit && activeStep !== 'NEW_OCCUPATION') {
            await api.post(`user/${user.user_id}/occupation/${selectedSpecialty.user_occupation_id}`, objToSend)

        } else {
            let res = await api.put(`user/${user.user_id}/occupation`, objToSend)
            setSelectedSpecialty({...selectedSpecialty, user_occupation_id: (res?.data?.user_occupation_id)})
        }
    }
    
    const handleChangeStep = (e) => {
        setStep(e)
    }

    const handleBack = () => {
        if (step === "OCCUPATION_SCHEDULE") {
            resetFields();
        }
        if (activeStep === 'NEW_OCCUPATION' && step === 'INITIAL') {
            onSaveData()
        }
        setStep(allSteps[allSteps.indexOf(step) - 1])
    }

    const defaultValuesEditOn = () => {
        setSelectedSpecialty(editData);

        let days = [];
        editData?.work_schedule.forEach((item) => days.push(item.dow));
        setScheduleList(days);

        let aux = {
            startTime: editData?.work_schedule[0].start1,
            endTime: editData?.work_schedule[0].end1,
            experience: editData?.experience,
            extra_fields: {registration: (editData.extra_fields.registration || "")}
        };

        setDataProfessional({...dataProfessional, ...aux})
    }

    const resetFields = () => {
        setSelectedSpecialty({})
        setDataProfessional({
            startTime:'08:00',
            endTime:'18:00',
        })
        setScheduleList([])
    }

    useEffect(() => {
        if (activeStep === 'EDIT_OCCUPATION') {
            if (editData) {
                defaultValuesEditOn()
            }
        }

        if (isEdit) {
            if (activeStep === 'NEW_OCCUPATION') {
                if (Object.keys(selectedSpecialty).length > 0) {
                    resetFields()
                }
                setStep('INITIAL')
            } else if (activeStep === 'EDIT_OCCUPATION') {
                setStep('OCCUPATION_SCHEDULE');
            }
        }

    }, [activeStep]);


    useImperativeHandle(ref, () => {
        return {mutateSubmit}
    })


    return (
        <div style={{minHeight:350}} id={'choose_occupation'}>
            <div className={'mt-3'}>
                {!isEdit &&
                    <>
                        <h3 className={'text-center'}>Agora você irá escolher sua ocupação</h3>
                        <p className={'text-center'}>
                            Escolha sua ocupação principal e os horários em que estará disponível para prestar serviço, depois se for necessário você poderá adicionar outras ocupações
                        </p>
                    </>
                }

                {(isLoading && !isEdit) && <div className={"row  gy-3 row-cols-1 row-cols-md-2 occupation-group mb-3"}>
                    {Array.from({length: 10}, (_, index) => (
                        <div key={index} className={"col"} style={{minHeight: 90}}>
                            <div className={"skeleton h-100"}
                            />
                        </div>
                    ))}
                </div>}

                <div>
                    {(activeStep === 'NEW_OCCUPATION' || !isEdit) &&
                        <div
                            className={`mb-3 ${(activeStep === 'NEW_OCCUPATION' ? 'd-block' : (step === 'INITIAL' || step === 'SERVICES_OFFERED') ? 'd-none' : 'd-block')}`}>
                            <BackButtonComponent onClick={() => handleBack()}
                                                 classNameIcon={'text-primary'}
                                                 position={{top:25, left:25}}
                            />
                        </div>
                    }
                    
                    <section className={`${(step === 'INITIAL') ? 'row' : 'd-none'}  gy-3 row-cols-1 row-cols-md-2 occupation-group `}>
                        {data?.map((category, index) => (
                            <div className={'col'} key={index}>
                                <button className="btn-reset p-2" onClick={() => getOccupationsGroup(category)}>
                                    <div className={'d-flex gap-2 flex-center mb-2'}>
                                        <img src={category?.group_occupation_image?.web} alt=""/>
                                        <h5>{category?.group_occupation_name}</h5>
                                    </div>
                                    <p>{category?.group_occupation_description}</p>
                                </button>
                            </div>
                        ))}
                    </section>


                    <section className={`occupation-group ${((step === 'CHOOSE_OCCUPATION') && occupationsGroups?.occupations.length > 0) ? 'd-block' : 'd-none'}`}>
                        <div className={'row gy-3 row-cols-1 row-cols-md-2 '}>
                            {occupationsGroups?.occupations?.map((item, index) => (
                                <div className={'col ' } key={index}>
                                    <div className={"position-relative"}>
                                        {(user?.occupations?.some((f) => f.occupation_id === item.id) && isRegisterNewOccupation) &&
                                            <button style={{marginTop: -10, marginRight: 10, zIndex: 900}}
                                                 className="position-absolute w-auto border-0 end-0 top-0 btn btn-success btn-sm badge text-white"
                                                 onClick={() => {
                                                     setSelectedOccupation && setSelectedOccupation(user?.occupations?.find((f) => f.occupation_id === item.id))
                                                     onSaveData()
                                                 }}
                                            >
                                                <small><FaPlus/> Adicionar serviço</small>
                                            </button>
                                        }
                                        <button className={`btn-reset px-2 py-3`}
                                                disabled={user?.occupations?.some((f) => f.occupation_id === item.id)}
                                                onClick={() => {
                                                    setSelectedSpecialty(occupationsGroups.occupations[index]);
                                                    handleChangeStep('OCCUPATION_SCHEDULE')
                                                }}>


                                            <div className={'d-flex gap-2 flex-center'}>
                                                <img src={item.icon} alt=""/>
                                                <h5>{item.name}</h5>
                                            </div>
                                            {item?.description && <p className={"mt-2"}>{item.description}</p>}
                                        </button>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </section>


                    <section id={'select_days_section'}
                             className={`${step === 'OCCUPATION_SCHEDULE' ? 'd-block' : 'd-none'}`}>
                        <div className={'border-bottom border-2'}>
                            <h3 className={'d-flex flex-column gap-2 flex-center'}>
                                <img src={selectedSpecialty?.icon} alt="" style={{maxWidth: 50}}/>
                                <span className={`${isEdit ? 'text-primary' : ''}`}>{selectedSpecialty?.name}</span>
                            </h3>
                        </div>
                        <div className={'mt-3'}>
                            <p>
                                Selecione os dias da semana em que está disponível
                            </p>

                            <CalendarRound list={days} selectedList={scheduleList} selected={(e) => setScheduleList(e)} isEdit={isEdit}/>

                            <div className={'row flex-center mt-3'}>
                                <div className={'col-6 col-md-4 col-lg-3'}>
                                    <button className={'btn-reset select-hours'} onClick={() => ofClock.current.showPicker()} type={'button'}>
                                        <span>Das</span>
                                        <input type="time" value={dataProfessional?.startTime} onChange={(e) => setDataProfessional({...dataProfessional, startTime: e.target.value})} ref={ofClock}/>
                                    </button>
                                </div>

                                <div className={'col-6 col-md-4 col-lg-3'}>
                                    <button className={'btn-reset select-hours'} onClick={() => toClock.current.showPicker()} type={'button'}>
                                        <span>Até</span>
                                        <input type="time"
                                               value={dataProfessional?.endTime}
                                               onChange={(e) => setDataProfessional({...dataProfessional, endTime: e.target.value})}
                                               ref={toClock}
                                        />
                                    </button>
                                </div>
                            </div>

                            <div className={'row flex-center mt-3'}>
                                <div className={'col-12 col-md-8'}>
                                    <label htmlFor="experience">Anos de experiência nessa ocupação:</label>
                                    <Select options={years}
                                            inputId={"experience"}
                                            value={years.filter((filter) => filter.value === dataProfessional?.experience) || null}
                                            onChange={(e) => setDataProfessional({...dataProfessional, experience: e?.value})}
                                            isSearchable={false}
                                            placeholder={'Selecione...'}
                                            menuPlacement={'auto'}
                                    />
                                </div>
                            </div>

                            {selectedSpecialty?.professional_registration &&
                                <div className={'row flex-center mt-3'}>
                                    <div className={'col-12 col-md-8'}>
                                        <label htmlFor="number_register"  className={'form-label'}>Informe aqui seu registro profissional:</label>
                                        <input type="text"
                                               value={dataProfessional?.extra_fields?.registration}
                                               onChange={(e) => setDataProfessional({...dataProfessional, extra_fields: {registration:e.target.value}})}
                                               className={'form-control'}
                                               id={'number_register'}
                                               placeholder={'Digite aqui'}
                                        />
                                    </div>
                                </div>
                            }


                            {(activeStep === 'NEW_OCCUPATION' || !isEdit) &&
                                <div className={`d-flex justify-content-end mt-3`}>
                                    <div className={'col-12 col-lg-4'}>
                                        <Button variant={'secondary'} className={'w-100'} disabled={isPending} onClick={mutateSubmit}>
                                            {activeStep === 'NEW_OCCUPATION' ? <>Adicionar nova ocupação</> : <>Continuar</>}
                                        </Button>
                                    </div>
                                </div>
                            }

                        </div>
                    </section>

                </div>

            </div>
        </div>
    )
}

export default forwardRef(ChooseOccupationComponent)