import React, {useState} from 'react'
import {Row, Tab, Col, Nav} from "react-bootstrap";
import {StepperItemComponent} from "./Components/StepperItemComponent";
import {AboutYouComponent} from "./Components/Steps/AboutYouComponent";
import {useSelector} from "react-redux";
import {useQueryClient} from "@tanstack/react-query";
import ChooseOccupationComponent from "./Components/Steps/ChooseOccupationComponent";
import {WorkerRegisterAddressComponent} from "./Components/Steps/WorkerRegisterAddressComponent";
import ServicesOfferedComponent from "./Components/ServicesOfferedComponent";
import {useHistory, useLocation} from "react-router-dom";
import {WelcomeRegisterComponent} from "./Components/Steps/WelcomeRegisterComponent";
import {SendDocumentsComponent} from "./Components/Steps/SendDocumentsComponent";
import {Env} from "../../helpers/Env";
import {checkDocsPending} from "../../hooks/Helpers";
import { FaRegGrinBeam } from "react-icons/fa";

const registerSteps = [
    { title: 'Bem-vindo (a)', subTitle: 'Primeiros passos para aumentar a visibilidade do seu negócio', step: 0, icon: <FaRegGrinBeam className={"fs-4"} /> },
    { title: 'Sobre você', subTitle: 'Conte quais suas habilidades e informações importante sobre você', step: 1 },
    { title: 'Escolha suas ocupações', subTitle: 'Escolha sua ocupação principal e os horários em que estará disponível para prestar serviço', step: 2 },
    { title: 'Serviços/produtos oferecidos', subTitle: 'Descreva seus serviços/produtos em específico', step: 3 },
    { title: 'Cadastro de endereço', subTitle: 'Local em que presta os serviços', step: 4 },
    { title: 'Validação de Documentos', subTitle: 'Envie seus documentos para confirmação de dados', step: 5, condition: Env.configs?.occupation_approved_required }
]

const registerNewOccupationSteps = [
    { title: 'Bem-vindo (a)', subTitle: 'Vamos cadastrar uma nova ocupação', icon: <FaRegGrinBeam className={"fs-4"} /> },
    { title: 'Escolha suas ocupações', subTitle: 'Escolha sua ocupação principal e os horários em que estará disponível para prestar serviço' },
    { title: 'Serviços/produtos oferecidos', subTitle: 'Descreva seus serviços/produtos em específico' },
]
export const ProviderRegistrationPage = () => {
    const [activeKey, setActiveKey] = useState(0);

    const user =  useSelector(state => state).authReducer;
    const history = useHistory();
    const queryCache = useQueryClient();
    const {data, status} = queryCache.getQueryState(['profile']);
    const location = useLocation();
    const isRegisterNewOccupation = location?.state?.isRegisterNewOccupation;
    const disableClickStep =  true

    const [selectedOccupation, setSelectedOccupation] = useState(null);

    const [steps, setSteps] = useState(isRegisterNewOccupation ? registerNewOccupationSteps : registerSteps);

    const handleChangeActiveKey = (key) => {
        const elem = document.querySelector('#tab-main')
        elem.scrollIntoView({behavior:'smooth'})
        setActiveKey(key)
    };

    const checkActiveStep = () => {
        if (!checkDocsPending(data)) {
            history.push('/painel')
        }
        if (data?.profile?.bio === null) {
            handleChangeActiveKey(1)
        } else if (data?.occupations.length === 0) {
            handleChangeActiveKey(2)
        } else if (data?.occupations?.length > 0 && data?.occupations[0]?.activities?.length === 0) {
            handleChangeActiveKey(3)

        } else if (data?.addresses?.length === 0) {
            handleChangeActiveKey(4)
        } else if (Env.configs?.occupation_approved_required && checkDocsPending(data)) {
            handleChangeActiveKey(5)
        } else {
            history.push('/painel')
        }
    }

    return (
        <div className={'my-5'}>
            <div className="container-fluid container-xl">
                <Tab.Container defaultActiveKey={'1'}>
                    <Row className={'g-4 g-md-3'}>
                        <Col sm={4}>
                            <div className="card border-0 shadow-sm">
                                <div className="card-body">
                                    <Nav className="stepper-nav flex-column gap-3">
                                        {steps.map(({ title, subTitle, icon, condition = true }, index) => condition && (
                                            <StepperItemComponent
                                                key={index}
                                                onClick={(e) => handleChangeActiveKey(e)}
                                                title={title}
                                                subTitle={subTitle}
                                                activeStep={activeKey}
                                                step={index}
                                                iconStep={icon}
                                                disabled={disableClickStep}
                                            />
                                        ))}
                                    </Nav>
                                </div>
                            </div>
                        </Col>
                        <Col sm={8} id={'tab-main'}>
                           <div className="card border-0 shadow">
                               <div className="card-body">
                                   {status === 'loading' ?
                                       <div>Carregando...</div> :
                                       <>
                                           {isRegisterNewOccupation ?
                                               <>
                                                   <Tab.Content>
                                                       <Tab.Pane eventKey={0} active={activeKey === 0}>
                                                           <WelcomeRegisterComponent resumeRegistration={data?.profile?.bio !== null}
                                                                                     handleStart={() =>  handleChangeActiveKey(1)}
                                                                                     isRegisterNewOccupation
                                                           />
                                                       </Tab.Pane>

                                                        <Tab.Pane eventKey={1} active={activeKey === 1}>
                                                           <div>
                                                               <ChooseOccupationComponent activeStep={activeKey}
                                                                                          eventKey={1}
                                                                                          setSelectedOccupation={setSelectedOccupation}
                                                                                          isRegisterNewOccupation={isRegisterNewOccupation}
                                                                                          onSaveData={() => handleChangeActiveKey(activeKey + 1)}
                                                               />
                                                           </div>
                                                       </Tab.Pane>

                                                       <Tab.Pane eventKey={2} active={activeKey === 2}>
                                                           <div>
                                                               <ServicesOfferedComponent occupation={selectedOccupation}
                                                                                         activeStep={(activeKey === 2)}
                                                                                         isEdit
                                                                                         isRegisterNewOccupation
                                                                                         handleBack={() => handleChangeActiveKey(1)}
                                                                                         onSaveData={() => handleChangeActiveKey(activeKey + 1)}
                                                               />
                                                           </div>
                                                       </Tab.Pane>

                                                   </Tab.Content>
                                               </>
                                               :
                                               <>
                                                   <Tab.Content>
                                                       <Tab.Pane eventKey={0} active={activeKey === 0}>
                                                           <WelcomeRegisterComponent resumeRegistration={data?.profile?.bio !== null}
                                                                                     handleStart={() => isRegisterNewOccupation ? handleChangeActiveKey(1) : checkActiveStep()}
                                                           />
                                                       </Tab.Pane>

                                                       <Tab.Pane eventKey={1} active={activeKey === 1}>
                                                           <AboutYouComponent bio={data?.profile?.bio}
                                                                              instagram={data?.profile?.instagram_link}
                                                                              facebook={data?.profile?.facebook_link}
                                                                              avatar={data?.profile?.profile_picture?.full}
                                                                              userId={data?.user_id}
                                                                              onClick={(e) => handleChangeActiveKey(2)}
                                                           />
                                                       </Tab.Pane>

                                                       <Tab.Pane eventKey={2} active={activeKey === 2}>
                                                           <div>
                                                               <ChooseOccupationComponent user={user}
                                                                                          activeStep={activeKey}
                                                                                          eventKey={2}
                                                                                          onSaveData={() => handleChangeActiveKey(3)}
                                                               />
                                                           </div>
                                                       </Tab.Pane>

                                                       <Tab.Pane eventKey={3} active={activeKey === 3}>
                                                           <div>
                                                               <ServicesOfferedComponent occupation={data?.occupations[0]}
                                                                                         user={user}
                                                                                         activeStep={(activeKey === 3 && !!data?.occupations[0]?.user_occupation_id)}
                                                                                         onSaveData={() => {
                                                                                             if (data?.addresses?.length === 0) {
                                                                                                 handleChangeActiveKey(4)
                                                                                             } else {
                                                                                                 handleChangeActiveKey(5)
                                                                                             }
                                                                                         }}
                                                               />
                                                           </div>
                                                       </Tab.Pane>

                                                       <Tab.Pane eventKey={4} active={activeKey === 4}>
                                                           <WorkerRegisterAddressComponent user={data}
                                                                                           activeStep={'INITIAL_REGISTER'}
                                                                                           onSaveData={() => Env.configs?.occupation_approved_required ? handleChangeActiveKey(5) : history.push('/painel')}
                                                           />
                                                       </Tab.Pane>

                                                       {Env.configs?.occupation_approved_required &&
                                                           <Tab.Pane eventKey={5} active={activeKey === 5}>
                                                               <SendDocumentsComponent user={data}/>
                                                           </Tab.Pane>
                                                       }
                                                   </Tab.Content>
                                               </>
                                           }
                                       </>
                                   }
                               </div>
                           </div>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </div>
    )
}