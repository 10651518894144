import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import {Env} from "./helpers/Env";
import {BrowserTracing} from "@sentry/tracing";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";

process.env.NODE_ENV  === 'production' &&Sentry.init({
    dsn: "https://8d27cb136a36485d952b7444741c9fc1@o516750.ingest.sentry.io/4504050421202944",
    integrations: [new BrowserTracing()],
    debug: !process.env.NODE_ENV  === 'production',
    environment: process.env.NODE_ENV  === 'production'? 'production' : 'development',
    release: Env.application_alias + '-web@' + Env.version,
    beforeBreadcrumb(breadcrumb, hint) {
        return breadcrumb.category === "console" ? null : breadcrumb;
    },
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus:false,
            retry:false,
        },

    }
})

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <App />
        {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
, document.getElementById('root'));

// If you want to start m
// easuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/*reportWebVitals();*/
