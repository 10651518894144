import React from 'react'
import welcome from "../../../../img/svg/pessoas.svg"

export const WelcomeRegisterComponent = ({handleStart, resumeRegistration, isRegisterNewOccupation}) => {
    return (
        <div className={'welcome'}>
            <h3>
                {resumeRegistration ? <>{isRegisterNewOccupation ? "Adicione ou edite seus serviços!" : "Falta pouco, para finalizar seu cadastro!"}</> : <> Bem-vindo(a) ao nosso Espaço de Oportunidades Profissionais!</>}
            </h3>
            <div className={'d-flex flex-center'}>
                <img src={welcome} alt="bem vindo"/>
            </div>
            <div className={'px-2 px-md-3'}>
                <p className={'text-gray-800'}>
                    Registre suas ocupações, horários, dias de atendimento e experiência para ampliar suas oportunidades profissionais. Conecte-se com clientes em busca dos seus serviços/produtos especializados e avance em sua carreira!
                </p>
            </div>
            <div className="d-flex flex-center mt-3">
                <button className={'btn btn-secondary col-12 col-md-6 col-lg-4'} onClick={handleStart}>
                    {resumeRegistration ? 'Continuar' : 'Começar'}
                </button>
            </div>
        </div>
    )
}
